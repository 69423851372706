
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { FetchPolicy } from 'apollo-client';
import BaseCard from '@/components/commonComponents/BaseCard.vue';
import DataTableHandler from '@/components/DataTable/DataTable';
import EventBus from '@/event-bus';
import DataTable from '@/components/DataTable/DataTable.vue';
import Message from '@/components/mixins/Message.vue';

@Component({
  name: 'ActionList',
  components: {
    DataTable,
    BaseCard,
  },
})

export default class ActionList extends Vue {
  private dataTable = new DataTableHandler('actionList');

  private title = '';

  private loading = false;

  private hasExport = false;

  async created(): Promise<void> {
    const forceRerender = () => this.fetchData();

    EventBus.$on('forceRerender', forceRerender);

    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('forceRerender', forceRerender);
    });
  }

  refreshData(): void {
    this.fetchData();
  }

  private fetchData(fetchPolicy: FetchPolicy = 'no-cache'): void {
    const filter = this.getFilter();

    const variables = {
      ...this.dataTable.getQueryVariables(),
      filter,
    };

    let graphqlOperation = '';
    if (this.$route.params.navigationGroup === 'tasks') {
      graphqlOperation = 'task-list-by-dossier-id';
      this.title = this.$t('modules.task.label');
    } else if (this.$route.params.navigationGroup === 'contact-moments') {
      graphqlOperation = 'contact-moment-list-by-dossier-id';
      this.title = this.$t('modules.contactmoment.label_plural');
    }

    this.loading = true;
    import(`@/graphql/queries/${graphqlOperation}`)
      .then(({ default: query }) => this.$apollo.query({
        fetchPolicy,
        query,
        variables,
      }))
      .then((response) => {
        this.dataTable.handleData(response.data);
        this.hasExport = response.data.list.dataGridInfo.metadata['list-settings']?.export ?? false;
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private exportFile(): void {
    this.loading = true;

    const filter = this.getFilter();
    const variables = {
      sorters: this.dataTable.getQueryVariables().sorters,
      filters: this.dataTable.getQueryVariables().filters,
      filter,
      ...this.dataTable.getQueryVariables(),
    };

    let graphqlOperation = '';
    if (this.$route.params.navigationGroup === 'tasks') {
      graphqlOperation = 'export-task-list-by-dossier-id';
    } else if (this.$route.params.navigationGroup === 'contact-moments') {
      graphqlOperation = 'export-contact-moment-list-by-dossier-id';
    }

    import(`@/graphql/queries/${graphqlOperation}`)
      .then(({ default: query }) => this.$apollo.query({
        query,
        variables,
      }))
      .then((response) => {
        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data.export.data}`;
        const downloadLink = document.createElement('a');
        const fileName = response.data.export.name;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private getFilter(): string {
    let filter: string|null;
    switch (this.$route.params.navigationSlug) {
      case 'my-tasks':
      case 'my-contact-moments':
        filter = 'MYOPEN';
        break;
      case 'all-tasks':
      case 'all-contact-moments':
        filter = 'ALLOPEN';
        break;
      case 'completed':
        filter = this.$route.params.navigationGroup === 'contact-moments' ? 'ALLCOMPLETED' : 'ALLARCHIVED';
        break;
      default:
        Message.error(this.$t('generic.error.no_data'));

        throw new Error(`
          No filter found for navigationGroup '${this.$route.params.navigationGroup}'
          and navigationSlug '${this.$route.params.navigationSlug}'
        `);
    }

    return filter;
  }
}
